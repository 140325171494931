export const menu = [
  {
    displayName: 'Service',
    path: '/atelier-service'
  },
  {
    displayName: 'Schmiedekurse',
    path: '/atelier-service/schmiedekurse'
  },
  {
    displayName: 'Umarbeitung',
    path: '/atelier-service/umarbeitung'
  },
  {
    displayName: 'Ehrensilber',
    path: '/atelier-service/ehrensilber'
  }
];